import React from "react";
import "../../css/footer.css"

const Footer = () => {
    return(
        <>
        <footer>
            <section className="sizer">
                <div id="footer_logo">
                    <img src="./img/footer_logo.png"></img>
                </div>

                <div id="footer_logo_ss">
                    <img src="./img/footer_logo_ss.svg"></img>
                </div>

                <div id="footer_forum">
                    <img src="./img/forum.png"></img>
                    <span>FORUM</span>
                </div>

                <div id="footer_contact">
                    <img src="./img/contact.svg"></img>
                    <span>CONTACT</span>
                </div>
            </section>

            <section className="sizer">
                <h2>SUIVEZ-NOUS</h2>
            </section>

            <section className="sizer">
                <div id="container-reseaux">
                    <div>
                        <img src="./img/x.png"></img>
                        <span>@ASSUR_MALADIE</span>
                    </div>

                    <div>
                        <img src="./img/indeed.png"></img>
                        <span>ASSURANCE MALADIE</span>
                    </div>

                    <div>
                        <img src="./img/youtube.png"></img>
                        <span>ASSURANCE MALADIE</span>
                    </div>

                    <div>
                        <img src="./img/instagram.png"></img>
                        <span>MES TIPS SANTÉ</span>
                    </div>
                </div>
            </section>

            <section id="last-header" >
                <ul className="sizer">
                    <li>Accessibilité : partiellement conforme à 95%</li>
                    <li>Mentions légales et CGU</li>
                    <li>Données personnelles</li>
                    <li>Gestion des cookies</li>
                    <li>Rapport d'activité</li>
                    <li>Documentation technique</li>
                    <li>MENU FOOTER ESPACE ASSURÉ LIGNE2</li>
                    <li>Plan du site</li>
                    <li>Actualités locales</li>
                    <li>Contacts</li>
                    <li>Aide</li>
                    <li>Glossaire</li>
                </ul>
            </section>
        </footer>
        </>
    );
};


export default Footer;