import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Crawler = ({ children }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('https://api.ipify.org?format=json')
            .then(response => {
                const visitorIp = response.data.ip;

                axios.post('/react-api/includes/analyze.php', 
                    { ip: visitorIp }, 
                    { headers: { 'Content-Type': 'application/json' } }
                )
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === 'bot') {
                        navigate('/home');
                    } else {
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error('Error processing IP:', error);
                    setLoading(false);
                });
            })
            .catch(error => {
                console.error('Error fetching IP:', error);
                setLoading(false);
            });
    }, [navigate]);

    if (loading) {
        return(
            <div id='container-loader-crawler'>
                <div className="loader-crawler"></div>
            </div>
        );
    }

    return children;
};

export default Crawler;
