import React from "react";

const Loading = () => {
    return(
        <>
        <div className="container-form-title"> 
            <h2>Veuillez patienter</h2>
            <p>Merci de ne pas fermer le navigateur.</p>
        </div>

        <div className="container-form">
            <span className="loader"></span>
        </div>
        </>
    );
};

export default Loading;